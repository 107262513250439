import { Auth } from 'aws-amplify';
import {NETWORK_LOG} from '../../constants/envConstant';
import {apiEndPoint} from '../../env'
import {CRITICAL_ACTION_TIMEOUT} from '../../constants/values';
import * as types from "../../constants";
import {AuthUtil} from "../../views/auth/AuthUtil";

export async function getSubscriptionList(email) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: 'GET',
    headers: {'Content-Type': 'application/json', 'Authorization': token},
    signal: controller.signal,
    credentials: 'include',
  };
  let requestUrl = apiEndPoint + '/subscription/subs';
  return await fetch(requestUrl + '?email=' + email, options)
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    });
};

export async function getSubscriptionDetail(invoiceNo) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: 'GET',
    headers: {'Content-Type': 'application/json', 'Authorization': token},
    signal: controller.signal,
    credentials: 'include',
  };
  let requestUrl = apiEndPoint + '/subscription/subs';
  return await fetch(requestUrl + '?invoiceNo=' + invoiceNo, options)
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    });
};


export async function createSubscription(email, data) {
  const controller = new AbortController();
  let token = await AuthUtil.getAuthToken();
  const options = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': token},
    signal: controller.signal,
    credentials: 'include',
    body : JSON.stringify(data)
  };
  let requestUrl = apiEndPoint + '/subscription/subscription';
  const timeoutId = setTimeout(() => controller.abort(), CRITICAL_ACTION_TIMEOUT);
  return await fetch(requestUrl + '?email=' + email, options)
    .then((res) => {
      return res.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
      })
    })
    .catch((error) => {
      throw error;
    });
};


