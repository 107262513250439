import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Footer from "../PageMain/Footer";

const PageAdditional = ({ reportDetails }) => {
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [comment, setComment] = useState("");
  let imgHeight = 0;

  const styles = {
    image_single: {
      maxWidth: "572px",
      position: "absolute",
      textAlign: "center",
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 0,
      boxSizing: "border-box",
      objectFit: "scale-down",
    },

    image_multi: {
      maxWidth: "572px",
      position: "absolute",
      textAlign: "center",
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 0,
      boxSizing: "border-box",
      objectFit: "scale-down",
    },
  };

  const requestOptions = {
    method: "GET",
    cache: "no-cache",
  };

  const getImage1 = () => {
    if (reportDetails.comments === undefined) {
      if (image1 === null && image2 === null) {
        return {
          component: <img style={{ height: "0px", background: "white" }}></img>,
        };
      } else if (image2 === null) {
        return {
          component: (
            <img
              style={{ ...styles.image_single, ...{ maxHeight: "600px" } }}
              src={image1}
              alt=""
            ></img>
          ),
        };
      } else {
        return {
          component: (
            <img
              style={{ ...styles.image_multi, ...{ maxHeight: "300px" } }}
              src={image1}
              alt=""
            ></img>
          ),
        };
      }
    } else {
      if (image1 === null && image2 === null) {
        return {
          component: <img style={{ height: "0px", background: "white" }}></img>,
        };
      } else if (image2 === null) {
        let textLine =
          Math.ceil(reportDetails.comments.length / 100) + 1; //100글자를 라인 1개로 생각
        imgHeight = 600 - textLine * 11;
        return {
          component: (
            <img
              style={{
                ...styles.image_single,
                ...{ maxHeight: imgHeight + "px" },
              }}
              src={image1}
              alt=""
            ></img>
          ),
        };
      } else {
        let textLine =
          Math.ceil(reportDetails.comments.length / 100) + 1; //100글자를 라인 1개로 생각
        imgHeight = 300 - textLine * 11;

        return {
          component: (
            <img
              style={{
                ...styles.image_multi,
                ...{ maxHeight: imgHeight + "px" },
              }}
              src={image1}
              alt=""
            ></img>
          ),
        };
      }
    }
  };

  const getImage2 = () => {
    return {
      component: (
        <img
          style={{ ...styles.image_multi, ...{ maxHeight: imgHeight + "px" } }}
          src={image2}
          alt=""
        ></img>
      ),
    };
  };

  const getImage = async (path) => {
    const image = await fetch(path, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((res) => {
          reader.onloadend = () => {
            res(reader.result);
          };
        });
      });
    return image;
  };

  useEffect(() => {
    const updateImage = async () => {
      if (reportDetails.aux_images === undefined) {
        setImage1(null);
        setImage2(null);
      } else {
        setComment(reportDetails.comments);

        if (reportDetails.aux_images.length === 0) {
          setImage1(null);
          setImage2(null);
        } else {
          let setFlag1 = false;
          let setFlag2 = false;
          let imageLen = reportDetails.aux_images.length;
          for (let i = 0; i < reportDetails.aux_images.length; i++) {
            if (reportDetails.aux_images[i].image.includes(".pdf")) {
              imageLen -= 1;
            } else {
              let retrieved_image = await getImage(
                  reportDetails.aux_images[i].image
              );
              if (!setFlag1) {
                setImage1(retrieved_image);
                setFlag1 = true;
              } else if (!setFlag2) {
                setImage2(retrieved_image);
                setFlag2 = true;
              }
            }
          }
          // To prevent to show existing images even though they have been removed
          if (imageLen == 0) {
            setImage1(null);
            setImage2(null);
          } else if (imageLen == 1) {
            setImage2(null);
          }
        }
      }
    };
    updateImage();
  }, [reportDetails, image1, image2]);

  return (
    <>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          display: "flex",
          flexDirection: "column",
          height: "720px",
        }}
      >
        <Typography
          style={{
            marginTop: 30,
            marginBottom: 10,
            textAlign: "start",
            fontWeight: 500,
            color: "black",
            fontStyle: "italic",
            fontSize: "12px",
          }}
        >
          Measurement Environments
        </Typography>

        {image1 === null ? null : (
          <div
            style={{
              flex: 1,
              paddingTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getImage1().component}
          </div>
        )}

        {image2 === null ? null : (
          <div
            style={{
              flex: 1,
              paddingTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getImage2().component}
          </div>
        )}

        {comment === "" ? null : (
          <div>
            <Typography
              style={{
                paddingTop: 20,
                color: "black",
                fontSize: "12px",
                fontWeight: 500,
                textAlign: "left",
                fontStyle: "italic",
              }}
            >
              Comments:
            </Typography>

            <Typography
              style={{
                marginTop: 5,
                color: "black",
                textAlign: "left",
                fontSize: "11px",
                fontWeight: 300,
              }}
            >
              {reportDetails.comments}
            </Typography>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PageAdditional;
