import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Auth } from "aws-amplify";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  IconButton,
} from "@material-ui/core";

import { display, spacing } from "@material-ui/system";
import _ from "lodash";
import "../../assets/css/order.css";
import ReportInterface from "../../integration/internal/ReportInterface";
import Loading from "../components/Loading";
import DialogContentText from "@material-ui/core/DialogContentText";
import { RadonUtil } from "../components/RadonUtil";
import { savePDF } from "@progress/kendo-react-pdf";
import { reformatRadonChartData } from "./util";
import { AuthUtil } from "../auth/AuthUtil";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { apiEndPoint } from "../../env";
import { CRITICAL_ACTION_TIMEOUT } from "../../constants/values";
import moment from "moment";
import ReportPreview from "./ReportPreview";
import ForPdf from "./ForPdf";
import {PAGE_ADDITIONAL, PAGE_RECOMMENDATIONS, PAGE_TWO} from "./ReportDetails";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

function ReportHistoryDetails() {
  const location = useLocation();
  const [reportDetails, setReportDetails] = useState({});
  const [reportDuration, setReportDuration] = useState({
    start: null,
    end: null,
  });
  const [showNoData, setShowNoData] = useState(false);
  const [page, setPage] = useState(1);

  const pdfExportComponent = useRef(null);
  const history = useHistory();
  const [reportChartRadonValue, setReportChartRadonValue] = useState([]);

  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageTwo, setPageTwo] = React.useState(false);
  const [pageAdditional, setPageAdditional] = React.useState(false);
  const [pageRecommendations, setPageRecommendations] = React.useState(false);
  const [lastPageIndex, setLastPageIndex] = React.useState(0);

  let res;

  useEffect(() => {
    const getReportDetails = async () => {
      let queryParams = new URLSearchParams(location.search);
      if(!location.search) {
        if (!location.state) return;
        res = await ReportInterface.retrieveReportHistoryDetails(
          location.state.email,
          location.state.serial_number,
          location.state.duration
        );

        if ((res && !res.data) || !res) {
          setReportDetails(null);
          setReportDuration({
            start: location.state.duration.start,
            end: location.state.duration.end,
            type: location.state.duration.type,
          });
          setShowNoData(true);
        } else if (res && !_.isEqual(reportDetails, res.data)) {
          let retData = reformatRadonChartData(
            res.data,
            RadonUtil.getBtnType(location.state.duration.type),
            location.state.duration.type
          );
          setReportChartRadonValue(retData);
          setReportDetails(res.data);
          setReportDuration({
            start: location.state.duration.start,
            end: location.state.duration.end,
            type: location.state.duration.type,
          });
          setPageVisible(res);
        }
      } else {
        let email = queryParams.get('email');
        let serialNumber = queryParams.get('serial_number');
        let measurementStart = queryParams.get('measurement_start');
        let measurementEnd = queryParams.get('measurement_end');
        let types = queryParams.get('types');
        let reportType = queryParams.get('duration');
        let duration = {start: measurementStart, end: measurementEnd, type: reportType, btn_type: types};

        if (serialNumber != null) {
          history.replace(history.location.pathname, {
            email: email,
            name: 'test name',
            certNo: 'cert no',
            actionType: "VIEW",
            serial_number: [serialNumber],
            duration: {
              start: measurementStart,
              end: measurementEnd,
              type: reportType,
            },
          });


          res = await ReportInterface.retrieveReportHistoryDetails(
            email,
            serialNumber,
            duration
          );

          if ((res && !res.data) || !res) {
            setReportDetails(null);
            setReportDuration(duration);
            setShowNoData(true);
          } else if (res && !_.isEqual(reportDetails, res.data)) {
            let retData = reformatRadonChartData(
              res.data,
              RadonUtil.getBtnType(duration.type),
              duration.type
            );
            setReportChartRadonValue(retData);
            setReportDetails(res.data);
            setReportDuration(duration);
            setPageVisible(res);
          }
        }
      }
    };

    getReportDetails();
  }, []);

  const setPageVisible = (res) => {
    if (res.data.measurement_duration === "48 Hours") {
      setPageTwo(true);
      setLastPageIndex(PAGE_TWO);

      //page Additional Info visible setting
      if (res.data.aux_images === undefined && res.data.comments === undefined) {
        setPageAdditional(false); //invisible
      } else {
        if (
            res.data.aux_images.length === 0 &&
            res.data.comments === ""
        ) {
          setPageAdditional(false); //invisible
        } else {
          setPageAdditional(true); //visible
          setLastPageIndex(PAGE_ADDITIONAL);
        }
      }

      //page ANSI-AARST MAH Recommendations
      if (res.data["ansi-aarst"] === undefined) {
        setPageRecommendations(false); //invisible
      } else {
        if (res.data["ansi-aarst"] === 0) {
          setPageRecommendations(false); //invisible
        } else {
          setPageRecommendations(true); //invisible
          setLastPageIndex(PAGE_RECOMMENDATIONS);
        }
      }
    }
  };

  const pageBefore = () => {
    if (pageIndex === 0) {
      setPageIndex(0);
    } else {
      if (pageIndex === PAGE_RECOMMENDATIONS) {
        //Recommendations
        if (pageAdditional) {
          setPageIndex(PAGE_ADDITIONAL); //move Additional Info
        } else {
          setPageIndex(PAGE_TWO); //move Page2
        }
      } else if (pageIndex === PAGE_ADDITIONAL) {
        //Additional info
        setPageIndex(PAGE_TWO); //move Page2
      } else {
        setPageIndex(pageIndex - 1);
      }
    }
  };

  const pageNext = () => {
    if (pageIndex === PAGE_TWO) {
      // Page2
      if (pageAdditional) {
        setPageIndex(PAGE_ADDITIONAL); //move Additional Info
      } else if (pageRecommendations) {
        setPageIndex(PAGE_RECOMMENDATIONS); //move Recommendations
      } else {
        setPageIndex(pageIndex); //last page
      }
    } else if (pageIndex === PAGE_ADDITIONAL) {
      //Additional Info
      if (pageRecommendations) {
        setPageIndex(PAGE_RECOMMENDATIONS); //move Recommendations
      } else {
        setPageIndex(pageIndex); //last page
      }
    } else if (pageIndex === PAGE_RECOMMENDATIONS) {
      //Recommendations
      setPageIndex(pageIndex); //last page
    } else {
      setPageIndex(pageIndex + 1);
    }
  };

  const handleClose = () => {
    setShowNoData(false);
    history.push({ pathname: "/reports/list" });
  };

  const convertPDF = (filename) => {
    let pdfRootHtmlElement = document.getElementById("pdfRoot");
    let res = drawDOM(pdfRootHtmlElement, {
      scale: 0.91,
      paperSize: "Letter",
      fileName: filename,
      margin: 0,
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        let encoded = dataUri.split(";base64,")[1];
        return encoded;
      });

    return res;
  };

  const handleSave = async () => {
    const fileName =
      reportDetails.serial_number +
      "_" +
      reportDetails.client_name +
      "_" +
      reportDuration.start +
      "_" +
      reportDuration.end +
      ".pdf";

    let pdfRootHtmlElement = document.getElementById("pdfRoot");

    try {
      const result = await convertPDF(fileName);

      const controller = new AbortController();
      let token = await AuthUtil.getAuthToken();
      let user = await Auth.currentAuthenticatedUser();
      let requestBody= {
        device_name: reportDetails.device_name,
        email: reportDetails.email,
        serial_number: reportDetails.serial_number,
        filename: fileName,
        client_name: reportDetails.client_name,
        client: {
          client_name: reportDetails.client_name,
          client_display: reportDetails.client_display,
          client_phone: reportDetails.client_phone,
        },
        address: reportDetails.address,
        activated: reportDetails.activated,
        deactivated: reportDetails.deactivated,
        device_location: reportDetails.device_location,
        device_placement: reportDetails.device_placement,
        calibration_date: reportDetails.calibration_date,
        generated_date: moment(reportDetails.generated_date).format("MM/DD/YYYY"),
        measurement_start: reportDuration.start,
        measurement_end: reportDuration.end,
        measurement_unit: reportDetails.measurement_unit,
        company_logo_url: reportDetails.company_logo_url,
        filebody: result,
        radondata: reportDetails.radondata,
        highest_radon: reportDetails.highest_radon,
        lowest_radon: reportDetails.lowest_radon,
        avg_radon: reportDetails.avg_radon,
        owner: {
          ownerDisplay: _.get(user, "attributes.custom:ownerDisplay"),
          certNo: _.get(user, "attributes.custom:certNo"),
          name: user.attributes.name,
        },
        measurement_duration: reportDetails.measurement_duration,
        req_type: reportDetails.req_type,
      };
      if (reportDetails.aux_images) requestBody.aux_images = reportDetails.aux_images;
      if (reportDetails.comments) requestBody.comments = reportDetails.comments;
      if (reportDetails["ansi-aarst"]) requestBody["ansi-aarst"] = reportDetails["ansi-aarst"];

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        signal: controller.signal,
        credentials: "include",
        body: JSON.stringify(requestBody),
      };
      let requestUrl = apiEndPoint + "/report";
      const timeoutId = setTimeout(
        () => controller.abort(),
        CRITICAL_ACTION_TIMEOUT
      );
      let fetchRes = await fetch(requestUrl, requestOptions);
      let uploadResult = await fetchRes.json();

      if (uploadResult.resultcode >= 200 && uploadResult.resultcode < 300) {
        window.open(uploadResult.data.pdf_url);
        history.push("/reports/list");
        document.body.appendChild(pdfRootHtmlElement);
        savePDF(pdfRootHtmlElement, {
          scale: 0.91,
          paperSize: "Letter",
          fileName,
          margin: 0,
        }, () => document.body.removeChild(pdfRootHtmlElement));
      } else {
        throw Error("failed upload the generated report");
      }
    } catch (e) {
      alert(e);
    }
  };

  const getNoDataDialog = () => {
    return (
      <>
        <Dialog
          titleStyle={{ textAlign: "center" }}
          maxWidth="md"
          maxHeight={"sm"}
          open={showNoData}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
        >
          <div className="s5"></div>
          <DialogTitle id="alert-dialog-title">Information Message</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              There is no radon data collected during the period.
            </DialogContentText>
          </DialogContent>
          <div className="s10"></div>
          <DialogActions style={{ paddingRight: "20px" }}>
            <Button
              style={{
                background: "#E0E0E0",
                color: "#000000",
                width: "100px",
                height: "36px",
              }}
              onClick={handleClose}
            >
              OK
            </Button>
          </DialogActions>
          <div className="s10"></div>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <Helmet title="Report Details" />
      <Typography variant="h3" gutterBottom display="inline">
        Radon Report
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/reports/list">
          Radon Reports
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <>
        {!reportDuration.start ? (
          <Loading></Loading>
        ) : !reportDetails ? (
          getNoDataDialog()
        ) : (
          <Container>
            <ForPdf
              pdfExportComponent={pdfExportComponent}
              reportDetails={reportDetails}
              reportChartRadonValue={reportChartRadonValue}
              pageTwo={pageTwo}
              pageAdditional={pageAdditional}
              pageRecommendations={pageRecommendations}
            />

            <Grid
              style={{
                marginTop: 20,
                flexDirection: "row",
              }}
              justify="center"
              container
            >
              <Grid
                item
                xs={1}
                container
                alignItems="center"
                justify="center"
              >
                {pageIndex > 0 && (
                    <IconButton size="small" onClick={pageBefore}>
                      <ArrowBackIos style={{ color: "#8F95B2" }} />
                    </IconButton>
                )}
              </Grid>
              <Grid item xs={10}>
                <ReportPreview
                  page={pageIndex}
                  reportDetails={reportDetails}
                  reportChartRadonValue={reportChartRadonValue}
                  pageTwo={pageTwo}
                  pageAdditional={pageAdditional}
                  pageRecommendations={pageRecommendations}
                />
              </Grid>
              <Grid
                item
                xs={1}
                container
                alignItems="center"
                justify="center"
              >
                {pageIndex < lastPageIndex && (
                    <IconButton size="small" onClick={pageNext}>
                      <ArrowForwardIos style={{ color: "#8F95B2" }} />
                    </IconButton>
                )}
              </Grid>
            </Grid>
            <Grid
              style={{
                marginTop: 20,
                flexDirection: "row",
              }}
              justify="center"
              container
            >
              <Grid item>
                <Button
                  alignItems="center"
                  justify="center"
                  onClick={handleSave}
                  style={{ width: "300px", marginTop: 0, marginBottom: 30 }}
                  size="large"
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Container>
        )}
      </>
    </>
  );
}

export default ReportHistoryDetails;
