import PageMain, {styles} from "./PageMain";
import { PageTwo } from "./PageTwo";
import {Typography} from "@material-ui/core";
import {PAGE_ADDITIONAL, PAGE_RECOMMENDATIONS, PAGE_TWO} from "./ReportDetails";
import PageAdditional from "./Page48h/PageAdditional";
import PageRecommendations from "./Page48h/PageRecommendations";

const ReportPreview = ({ page, reportDetails, reportChartRadonValue, pageTwo, pageAdditional, pageRecommendations }) => {
  return (
    <div>
      {page === 0 && (
        <div
          className="pdf-body"
          style={{
            padding: "25px 25px 5px",
            height: "100%",
            border: "1px solid #E6E8F0",
            backgroundColor: "white",
          }}
        >
          <PageMain
            reportDetails={reportDetails}
            reportChartRadonValue={reportChartRadonValue}
            isForPDF={false}
          />
        </div>
      )}
      {pageTwo && (
        <div
            className="pdf-body-two"
            hidden={page !== PAGE_TWO}
            style={{
              padding: "25px 25px 5px",
              height: "100%",
              border: "1px solid #E6E8F0",
              backgroundColor: "white",
            }}
        >
          <PageTwo
              reportDetails={reportDetails}
              reportChartRadonValue={reportChartRadonValue}
          />
        </div>
      )}
      {pageAdditional && (
          <div
              className="pdf-body-addition"
              hidden={page !== PAGE_ADDITIONAL}
              style={{
                padding: "25px 25px 5px",
                height: "100%",
                border: "1px solid #E6E8F0",
                backgroundColor: "white",
              }}
          >
            <PageAdditional reportDetails={reportDetails} />
          </div>
      )}
      {pageRecommendations && (
          <div
              className="pdf-body-recommendation"
              hidden={page !== PAGE_RECOMMENDATIONS}
              style={{
                padding: "25px 25px 5px",
                height: "100%",
                border: "1px solid #E6E8F0",
                backgroundColor: "white",
              }}
          >
            <PageRecommendations />
          </div>
      )}
    </div>
  );
};

export default ReportPreview;
