/* eslint-disable import/first */
import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";
import NoAuthGuard from "../components/NoAuthGuard";

// Auth components
import SignIn from "../views/auth/SignIn";
// import SignInHidden from "../views/auth/SignInHidden";
import RedirectToRoot from "../views/auth/RedirectToRoot";
import VerifyPasscode from "../views/auth/VerifyPasscode";
import SignUp from "../views/auth/SignUp";
import ForgotPassword from "../views/auth/ForgotPassword";
import ResetPassword from "../views/auth/ResetPassword";
import PasscodeRequired from "../views/auth/PasscodeRequired";
import ResetPwdConfirm from "../views/auth/ResetPwdConfirm";
import Page404 from "../views/auth/Page404";
import Page500 from "../views/auth/Page500";
import EcoGuardGuide from "../views/noauth/EcoGuardGuide";
import Success from "../views/account/Success";

// Components components
import Accordion from "../views/components/Accordion";
import Alerts from "../views/components/Alerts";
import Avatars from "../views/components/Avatars";
import Badges from "../views/components/Badges";
import Buttons from "../views/components/Buttons";
import Cards from "../views/components/Cards";
import Chips from "../views/components/Chips";
import Dialogs from "../views/components/Dialogs";
import Lists from "../views/components/Lists";
import Menus from "../views/components/Menus";
import Pagination from "../views/components/Pagination";
import Progress from "../views/components/Progress";
import Snackbars from "../views/components/Snackbars";
import Tooltips from "../views/components/Tooltips";

// Dashboards components
const Default = async(() => import("../views/dashboards/Devices"));
const Analytics = async(() => import("../views/dashboards/Analytics"));
const SaaS = async(() => import("../views/dashboards/SaaS"));
const DeviceDetail = async(() =>
  import("../views/dashboards/Devices/DeviceDetail")
);

// Forms components
import SelectionCtrls from "../views/forms/SelectionControls";
import Selects from "../views/forms/Selects";
import TextFields from "../views/forms/TextFields";
const Pickers = async(() => import("../views/forms/Pickers"));
const Dropzone = async(() => import("../views/forms/Dropzone"));
const Editors = async(() => import("../views/forms/Editors"));
const Formik = async(() => import("../views/forms/Formik"));

// Icons components
import MaterialIcons from "../views/icons/MaterialIcons";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import TimelineIcon from "@material-ui/icons/Timeline";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
const FeatherIcons = async(() => import("../views/icons/FeatherIcons"));
import RoomIcon from "@material-ui/icons/Room";

// Pages components
import Blank from "../views/pages/Blank";
import OrderList from "../views/invoices/OrderList";
import OrderDetails from "../views/invoices/OrderDetails";
import SubscriptionList from "../views/invoices/SubscriptionList";
import SubscriptionDetails from "../views/invoices/SubscriptionDetails";
import ReportList from "../views/reports/ReportList";
import ReportDetails from "../views/reports/ReportDetails";
import ReportHistoryDetails from "../views/reports/ReportHistoryDetails";
import InvoiceSampleDetails from "../views/pages/InvoiceSampleDetails";
import InvoiceSampleList from "../views/pages/InvoiceSampleList";
import OrderSampleList from "../views/pages/OrderSampleList";
import Pricing from "../views/pages/Pricing";
import SettingsSample from "../views/pages/SettingsSample";
import Setting from "../views/account/Setting";
import RadonMap from "../views/service/RadonMap";
import Projects from "../views/pages/Projects";
import Chat from "../views/pages/Chat";
const Profile = async(() => import("../views/pages/Profile"));
const Tasks = async(() => import("../views/pages/Tasks"));
const Calendar = async(() => import("../views/pages/Calendar"));

// Tables components
import SimpleTable from "../views/tables/SimpleTable";
import AdvancedTable from "../views/tables/AdvancedTable";

// Chart components
const Chartjs = async(() => import("../views/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../views/maps/GoogleMaps"));
const VectorMaps = async(() => import("../views/maps/VectorMaps"));

// Documentation
import Welcome from "../views/docs/Welcome";
import GettingStarted from "../views/docs/GettingStarted";
import EnvironmentVariables from "../views/docs/EnvironmentVariables";
import Deployment from "../views/docs/Deployment";
import Theming from "../views/docs/Theming";
import StateManagement from "../views/docs/StateManagement";
import APICalls from "../views/docs/APICalls";
import ESLintAndPrettier from "../views/docs/ESLintAndPrettier";
import Support from "../views/docs/Support";
import Changelog from "../views/docs/Changelog";

// Landing
import Landing from "../views/presentation/Landing";

// Protected routes
import ProtectedPage from "../views/protected/ProtectedPage";
import NoDevices from "../views/dashboards/Devices/NoDevices";
import Timeline from "@material-ui/lab/Timeline";
import AvReportDetails from "../views/reports/AvReportDetails";
import ReportListForApp from "../views/reports/ReportListForApp";
import IntegrateWithApp from "../views/auth/IntegrateWithApp";
import SupportPage from "../views/account/support";
import EUReport from "../views/noauth/EUReport";
import EndUserReport from '../views/noauth/EndUserReport';

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/",
      component: RedirectToRoot,
    },
    {
      path: "/devices",
      // name: "Devices",
      component: Default,
    },
    // {
    //   path: "/no",
    //   // name: "No Devices Found",
    //   component: NoDevices,
    // },
    {
      path: "/device/detail",
      component: DeviceDetail,
    },
  ],
  component: null,
};

const dashboardsRoutesForSideBar = {
  id: "Dashboard",
  path: "/",
  // badge: "v2.0.3",
  icon: <Layout />,
  component: Default,
  children: null,
};

const dashboardsSampleRoutes = {
  id: "Dashboard (sample)",
  path: "/dashboard/sample/",
  header: "Reference",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/sample/",
      name: "Default",
      component: Default,
    },
    {
      path: "/dashboard/sample/analytics",
      name: "Analytics",
      component: Analytics,
    },
    {
      path: "/dashboard/sample/saas",
      name: "SaaS",
      component: SaaS,
    },
  ],
  component: null,
};

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/pages/settings",
      name: "Settings",
      component: SettingsSample,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/pages/chat",
      name: "Chat",
      component: Chat,
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank,
    },
  ],
  component: null,
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  children: null,
};

const authRoutesForSideBar = {
  id: "Auth",
  path: "/auth",
  // header: "Eco Implementation",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/integrate-app",
      name: "Integrate With App",
      component: IntegrateWithApp,
    },
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    // {
    //   path: "/auth/sign-in/hidden",
    //   name: "Sign In",
    //   component: SignInHidden,
    // },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/verify-code",
      name: "Verify Code",
      component: VerifyPasscode,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/reset-pwd-confirm",
      name: "Reset Password Confirm",
      component: ResetPwdConfirm,
    },
    {
      path: "/auth/passcode-required",
      name: "Passcode Required",
      component: PasscodeRequired,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/auth/integrate-app",
      name: "Integrate With App",
      component: IntegrateWithApp,
    },
  ],
  component: null,
};

const noauthRoutes = {
  id: "NoAuth",
  path: "/qr",
  icon: <Users />,
  children: [
    {
      path: "/qr",
      name: "QR Access",
      component: EcoGuardGuide,
    },
    {
      path: "/qr/success",
      name: "Success",
      component: Success,
    },
  ],
  component: null,
};

const settingsRoutes = {
  id: "Settings",
  path: "/settings",
  icon: <SettingsIcon />,
  children: [
    {
      path: "/settings/account",
      name: "Settings",
      component: Setting,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const serviceRoutes = {
  id: "Service",
  path: "/service",
  icon: <RoomIcon />,
  children: [
    {
      path: "/service/radon-map",
      name: "Radon Map",
      component: RadonMap,
    },
  ],
  component: null,
};

const invoiceRoutes = {
  id: "Invoices",
  path: "/invoices",
  icon: <CreditCard />,
  children: [
    {
      path: "/orders",
      name: "Orders",
      component: OrderList,
      guard: AuthGuard,
    },
    {
      path: "/orders/detail",
      name: "Order Details",
      component: OrderDetails,
      guard: AuthGuard,
    },
    {
      path: "/subscriptions",
      name: "Subscriptions",
      component: SubscriptionList,
      guard: AuthGuard,
    },
    {
      path: "/subscriptions/detail",
      name: "Subscriptions Details",
      component: SubscriptionDetails,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const reportRoutes = {
  id: "Report",
  path: "/reports",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/reports/list",
      // name: "Devices",
      component: ReportList,
      guard: AuthGuard,
    },
    {
      path: "/reports/list-for-app",
      // name: "Devices",
      component: ReportListForApp,
    },
    {
      path: "/reports/details",
      component: ReportDetails,
      guard: AuthGuard,
    },
    {
      path: "/reports/history/details",
      component: ReportHistoryDetails,
      guard: AuthGuard,
    },
    {
      path: "/reports/advanced-details",
      component: AvReportDetails,
    },
    // {
    //   path: "/no",
    //   // name: "No Devices Found",
    //   component: NoDevices,
    // },
  ],
  component: null,
  guard: AuthGuard,
};

const noAuthReportRoutes = {
  id: "userReport",
  path: "/eu",
  icon: <CreditCard />,
  containsHome: true,
  children: [
    {
      path: "/eu/report",
      component: EndUserReport,
      guard: NoAuthGuard,
    },
    // {
    //   path: "/eu/all",
    //   component: EUReport,
    //   guard: NoAuthGuard,
    // },
  ],
  component: null,
  guard: NoAuthGuard,
};

const supportRoutes = {
  id: "Support",
  path: "/",
  icon: <InfoIcon />,
  children: [
    {
      path: "/support",
      component: SupportPage,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const reportsForSideBar = {
  id: "Radon reports",
  path: "/reports/list",
  icon: <AssessmentIcon />,
  component: ReportList,
  children: null,
};

const ordersForSideBar = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: OrderList,
  children: null,
};

const subscriptoinsForSideBar = {
  id: "Subscriptions",
  path: "/subscriptions",
  icon: <CreditCardIcon />,
  component: SubscriptionList,
  children: null,
};

const settingsForSideBar = {
  id: "Settings",
  path: "/settings/account",
  icon: <SettingsIcon />,
  component: Setting,
  children: null,
};

const supportForSideBar = {
  id: "Support",
  path: "/support",
  icon: <InfoIcon />,
  component: SupportPage,
  children: null,
};

const serviceForSideBar = {
  id: "Radon Map",
  path: "/service/radon-map",
  icon: <RoomIcon />,
  component: RadonMap,
  children: null,
};

const qrAccessRoutes = {
  id: "qrAccess",
  path: "/auth",
  icon: <RoomIcon />,
  children: [
    {
      path: "/auth/qraccess",
      name: "QR Access",
      component: SignUp,
    },
  ],
  component: null,
};

// Sample
const invoiceSampleRoutes = {
  id: "Invoices (sample)",
  path: "/invoicesSample",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoicesSample",
      name: "List",
      component: InvoiceSampleList,
    },
    {
      path: "/invoicesSample/detail",
      name: "Details",
      component: InvoiceSampleDetails,
    },
  ],
  component: null,
};

// Sample
const orderSampleRoutes = {
  id: "Order (sample)",
  path: "/orderSampleList",
  icon: <ShoppingCart />,
  component: OrderSampleList,
  children: null,
};

const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  component: Tasks,
  children: null,
};

const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  component: Calendar,
  children: null,
};

const componentsRoutes = {
  id: "Components",
  path: "/components",
  header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/alerts",
      name: "Alerts",
      component: Alerts,
    },
    {
      path: "/components/accordion",
      name: "Accordion",
      component: Accordion,
    },
    {
      path: "/components/avatars",
      name: "Avatars",
      component: Avatars,
    },
    {
      path: "/components/badges",
      name: "Badges",
      component: Badges,
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "/components/cards",
      name: "Cards",
      component: Cards,
    },
    {
      path: "/components/chips",
      name: "Chips",
      component: Chips,
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      component: Dialogs,
    },
    {
      path: "/components/lists",
      name: "Lists",
      component: Lists,
    },
    {
      path: "/components/menus",
      name: "Menus",
      component: Menus,
    },
    {
      path: "/components/pagination",
      name: "Pagination",
      component: Pagination,
    },
    {
      path: "/components/progress",
      name: "Progress",
      component: Progress,
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      component: Snackbars,
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      component: Tooltips,
    },
  ],
  component: null,
};

const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      component: Pickers,
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      component: SelectionCtrls,
    },
    {
      path: "/forms/selects",
      name: "Selects",
      component: Selects,
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      component: TextFields,
    },
    {
      path: "/forms/dropzone",
      name: "Dropzone",
      component: Dropzone,
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors,
    },
    {
      path: "/forms/formik",
      name: "Formik",
      component: Formik,
    },
  ],
  component: null,
};

const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      component: SimpleTable,
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      component: AdvancedTable,
    },
  ],
  component: null,
};

const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      component: MaterialIcons,
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      component: FeatherIcons,
    },
  ],
  component: null,
};

const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  children: null,
};

const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const documentationRoutes = {
  id: "Documentation",
  path: "/documentation",
  header: "Material App",
  icon: <BookOpen />,
  children: [
    {
      path: "/documentation/welcome",
      name: "Welcome",
      component: Welcome,
    },
    {
      path: "/documentation/getting-started",
      name: "Getting Started",
      component: GettingStarted,
    },
    {
      path: "/documentation/environment-variables",
      name: "Environment Variables",
      component: EnvironmentVariables,
    },
    {
      path: "/documentation/deployment",
      name: "Deployment",
      component: Deployment,
    },
    {
      path: "/documentation/theming",
      name: "Theming",
      component: Theming,
    },
    {
      path: "/documentation/state-management",
      name: "State Management",
      component: StateManagement,
    },
    {
      path: "/documentation/api-calls",
      name: "API Calls",
      component: APICalls,
    },
    {
      path: "/documentation/eslint-and-prettier",
      name: "ESLint & Prettier",
      component: ESLintAndPrettier,
    },
    {
      path: "/documentation/support",
      name: "Support",
      component: Support,
    },
  ],
  component: null,
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v2.0.3",
  icon: <List />,
  component: Changelog,
  children: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  reportRoutes,
  pagesRoutes,
  projectsRoutes,
  supportRoutes,
  orderSampleRoutes,
  invoiceSampleRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
  invoiceRoutes,
  settingsRoutes,
  serviceRoutes
];

export const euReportLayoutRoutes = [
  noAuthReportRoutes
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];
export const noauthLayoutRoutes = [noauthRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutesForSideBar,
  reportsForSideBar,
  ordersForSideBar,
  subscriptoinsForSideBar,
  settingsForSideBar,
  supportForSideBar,
  // serviceForSideBar,
  // authRoutesForSideBar,
  // documentationRoutes,
  // changelogRoutes,
  // dashboardsSampleRoutes,
  // pagesRoutes,
  // invoiceSampleRoutes,
  // orderSampleRoutes,
  // projectsRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
];

export const sidebarForTestRoutes = [
  dashboardsRoutesForSideBar,
  reportsForSideBar,
  ordersForSideBar,
  subscriptoinsForSideBar,
  settingsForSideBar,
  supportForSideBar,
  authRoutesForSideBar,
  documentationRoutes,
  supportRoutes,
  changelogRoutes,
  dashboardsSampleRoutes,
  pagesRoutes,
  invoiceSampleRoutes,
  orderSampleRoutes,
  projectsRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
];
