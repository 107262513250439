import { Divider, Grid, Typography } from "@material-ui/core";
import {MEASUREMENT_UNIT, UNIT_CONVERSION_SCALE} from "../../../constants/values";
import moment from "moment/moment";

const Data = ({ reportDetails, reportChartRadonValue }) => {
  let new48Array = [];

  if (reportChartRadonValue.length > 48) {
    for (let i = 0; i < 48; i++) {
      new48Array.push(reportChartRadonValue[i]);
    }
  } else {
    new48Array = reportChartRadonValue;
  }

  const reformatRadonData = (data, unit, point) => {
    if (data === "" || data === undefined) {
      return "N/A";
    } else {
      if (unit === 0) {
        return (data / UNIT_CONVERSION_SCALE).toFixed(point) + " pCi/L";
      } else {
        return data.toFixed(0) + " Bq/m3";
      }
    }
  };

  return (
    <>
      <Grid container style={{ marginBottom: "15px" }}>
        <Grid item xs={6}>
          <Typography style={{ fontSize: "14px", textDecoration: "underline" }}>
            Overall Average: {reformatRadonData(
              reportDetails.avg_radon,
              reportDetails.measurement_unit,
              1
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} container direction="column">
          <Grid
            item
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ textDecoration: "underline" }}>
              Measurement started
            </Typography>
            <Typography>
              {reportDetails.measurement_start_d
                  ? moment(reportDetails.measurement_start_d).format(
                      "MM/DD/YYYY hh:mm A"
                  )
                  : "N/A"}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ textDecoration: "underline" }}>
              Measurement finished
            </Typography>
            <Typography>
              {reportDetails.measurement_end_d
                  ? moment(reportDetails.measurement_end_d).format(
                      "MM/DD/YYYY hh:mm A"
                  )
                  : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid container style={{ marginTop: "15px", marginBottom: "21px" }}>
        <Grid item container xs={12}>
          <Grid item container xs={4}>
            <Grid item>
              <Typography style={{ textDecoration: "underline" }}>
                Data #
              </Typography>
              {new48Array.slice(0, 16).map((data, index) => {
                return <Typography>{index + 1}</Typography>;
              })}
            </Grid>
            <Grid item style={{ paddingLeft: "10px" }}>
              <Typography style={{ textDecoration: "underline" }}>
                {`Radon (${MEASUREMENT_UNIT[reportDetails.measurement_unit]})`}
              </Typography>
              {new48Array.slice(0, 16).map((data) => {
                return <Typography>{data.radon_level}</Typography>;
              })}
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            <Grid item>
              <Typography style={{ textDecoration: "underline" }}>
                Data #
              </Typography>
              {new48Array.slice(16, 32).map((data, index) => {
                return <Typography>{index + 17}</Typography>;
              })}
            </Grid>
            <Grid item style={{ paddingLeft: "10px" }}>
              <Typography style={{ textDecoration: "underline" }}>
                {`Radon (${MEASUREMENT_UNIT[reportDetails.measurement_unit]})`}
              </Typography>
              {new48Array.slice(16, 32).map((data, index) => {
                return <Typography>{data.radon_level}</Typography>;
              })}
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            <Grid item>
              <Typography style={{ textDecoration: "underline" }}>
                Data #
              </Typography>
              {new48Array.slice(32, 48).map((data, index) => {
                return <Typography>{index + 33}</Typography>;
              })}
            </Grid>
            <Grid item style={{ paddingLeft: "10px" }}>
              <Typography style={{ textDecoration: "underline" }}>
                {`Radon (${MEASUREMENT_UNIT[reportDetails.measurement_unit]})`}
              </Typography>
              {new48Array.slice(32, 48).map((data, index) => {
                return <Typography>{data.radon_level}</Typography>;
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Data;
